@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  word-break: break-word;
}

.app-container * {
  font-family: inherit !important;
}

svg {
  display: block;
}

ul,
ol {
  font-size: 16px;
  color: #616771;
}

.p_relative {
  position: relative;
}

.max_height400 {
  max-height: 400px;
}

.p_0 {
  padding: 0;
}

.px-144 {
  padding-left: 144px;
  padding-right: 144px;
}

.fs_40 {
  font-size: 40px;
}

.border_0 {
  margin: 0 !important;
}

.border_r8 {
  border-radius: 8px;
}

.custom_portal_switch {
  margin-top: 30px;
}

.border_tooltip {
  border-bottom: 1px dotted #1c2433;
}

.border_bottom_lr_6 {
  border-bottom-left-radius: 6px;
}

.border_bottom_rr_6 {
  border-bottom-right-radius: 6px;
}

.border_bottom_tr_6 {
  border-top-right-radius: 6px;
}

.border_bottom_tl_6 {
  border-top-left-radius: 6px;
}

.border_top_light {
  border-top: 1px solid #e0e1e3;
}

.border_rounded {
  border-radius: 100%;
}

.m_auto {
  margin: 0 auto;
}

.text_ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden {
  display: none;
}

.inte-wrapper,
body {
  background-color: var(--inte-G0);
}

.inte-pageHeader--fixed {
  background-color: rgb(255 255 255);
}

.inte__Topbar {
  background-color: var(--inte-G10);
}

.inte__Sidebar {
  padding: 20rem 1.6rem 7rem;
}

.inte__Sidebar.inte__Sidebar--hasLogo {
  padding-top: 40px;
}

.showcustomerrorborderdescription .inte-formElementTextfield {
  border: 1px solid rgb(214, 44, 32);
}

.showcustomerrorborderdescription .inte-form__itemHelp {
  color: rgb(214, 44, 32);
}

.showcustomerrorborderimage .ant-upload-select-picture-card {
  border: 1px solid rgb(214, 44, 32);
}

@media screen and(min-width: 992px) {
  .inte__SidebarItems {
    height: calc(100vh - 16rem);
  }
}

.inte__Sidebar .inte__SidebarItems>ul+ul::after {
  left: 16px;
  right: 5px;
}

.inte__Sidebar .inte__SidebarItems>ul:before {
  content: 'General';
  font-size: 13px;
  font-weight: 400;
  color: #1c2433;
  line-height: 20px;
  margin-left: 16px;
  margin-bottom: 8px;
}

.inte__Sidebar .inte__SidebarItems>ul+ul::before {
  content: 'Manage';
  font-size: 13px;
  font-weight: 400;
  color: #1c2433;
  line-height: 20px;
  margin-left: 16px;
  margin-bottom: 8px;
}

.inte__Sidebar--ConnectTop+ul {
  margin-top: 28px;
}

.inte--logoWrapper {
  background-color: #fff;
}

.inte__Sidebar .inte__SidebarItems ul.inte-flex li.inte-flex__item.custom_logout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 95%;
  margin-right: 0;
  padding-left: 1.6rem;
  padding-right: 2.2rem;
  padding-top: 2.2rem;
  padding-bottom: 1rem;
  background-color: #ffffff;
  margin-bottom: -0.1rem;
  z-index: 1;
}

.inte__Sidebar--OutSideClick {
  z-index: 999;
}

.inte__Sidebar .inte__SidebarItems .custom_logout .inte__Menus::before {
  position: absolute;
  content: '';
  height: 0.1rem;
  background-color: var(--inte-G42);
  left: 0;
  top: -1.2rem;
  transition: 300ms all linear;
  right: 0;
}

.inte__user {
  font-weight: 600;
}

/**Badge css **/
.inte--Badge {
  padding: 5.5px 8px;
  border-radius: 16px;
}

.inte--BadgeNarrow.inte--Badge-content span {
  font-size: 1.3rem;
  line-height: 2;
}

.inte__BadgePositive--100 .inte__text.inte__text--dark,
.inte__BadgePositive--100 {
  color: rgba(2, 122, 72, 1) !important;
}

.inte__BadgeWarning--100 .inte__text.inte__text--dark {
  color: rgba(204, 143, 51, 1) !important;
}

.inte__BadgeNegative--100 .inte__text.inte__text--dark {
  color: rgba(196, 40, 28, 1) !important;
}

.custom_account .inte__Menus {
  margin-bottom: 70px;
}

.Badge__Active .inte--Badge {
  color: var(--inte-GR300);
}

.Badge__PastDue .inte--Badge {
  color: #9a3bbc;
}

.Badge__Cancelled .inte--Badge {
  color: var(--inte-R400);
}

.Badge__Expired .inte--Badge {
  color: var(--inte-G800);
}

.Badge__Trailing .inte--Badge {
  color: #3ba5bc;
}

.Badge__Unpaid .inte--Badge {
  color: #bc3b81;
}

.Badge__Incomplete .inte--Badge {
  color: #3b67bc;
}

.Badge__Outdated .inte--Badge {
  color: #3ba5bc;
}

.Badge__Pending .inte--Badge {
  color: #8f5600;
}

/**Badge css ends **/
.inte-pageHeader .inte--BreadCrumbs .inte-btn.inte__plain-btn {
  background-color: unset;
  padding: 0.4rem 0;
}

.inte-pageHeader .inte--BreadCrumbs .inte-flex__item svg {
  stroke: #b5b7bc;
}

.inte-pageHeader .inte-pageHeader--Title .Heading {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600 !important;
  color: var(--inte-G800) !important;
}

.inte--BreadCrumbs .inte__plain-btn .inte__text {
  line-height: 1.6rem;
  color: var(--inte-G800);
}

.inte--BreadCrumbs [disabled].inte__plain-btn .inte__text {
  color: var(--inte-G90);
}

/**Form elements **/
.custom__reverseNavigation .inte-btn {
  height: 3.6rem;
  width: 3.6rem;
  min-height: 2.6rem;
}

.inte-btn.inte-btn--Outlined .inte__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.inte-btn .inte__text {
  font-weight: 600;
  font-size: 1.6rem;
}

.inte-formElement {
  border-radius: 6px;
  border: 0.1rem solid var(--inte-G40);
}

.inte-formElemet__Arrow svg {
  stroke: #70747e;
}

.inte-checkbox>label:hover .inte-checkboxWrap .inte__checkboxEle--fake {
  border-color: var(--inte-P1000);
  background-color: var(--inte-P20) !important;
}

.inte__checkboxWrap .inte__checkbox {
  height: 2rem;
  width: 2rem;
}

.inte__checkboxWrap .inte__checkbox-Label,
.inte__RadioWrap .inte__Radion-Label {
  font-size: 1.6rem;
}

.inte-btnNarrow {
  border-radius: var(--radius-rounded-6);
  height: 4.4rem;
}

.inte-btn.inte__text-btn {
  height: unset;
}

.inte-select::after,
.inte-formElement--hasSuffix .inte-form__suffix::after {
  content: '|';
  position: absolute;
  right: 38px;
  font-size: 22px;
  color: #e0e1e3;
}

.inte-formElement.inte-select {
  padding: 1rem 4.8rem 1rem 1.2rem !important;
  border-radius: 6px;
}

.inte-formElement--hasSuffix .inte-formElement {
  border-right: 0;
}

.inte-formElement--hasSuffix .inte-form__suffix {
  border: 0.1rem solid var(--inte-G40);
  border-left: 0;
  background-color: unset;
}

.inte-select-options li.inte-Select__Select--Item .inte__text {
  color: var(--inte-G800);
  font-size: 1.6rem;
  line-height: 2;
  font-weight: var(--weight-normal);
}

.inte-Select__Select--Item:active,
.inte-Select__Select--Item:not(.inte-Select__Select--ItemGrouped):hover {
  background-color: var(--inte-P30);
}

::placeholder,
.ant-picker-input>input::placeholder,
.inte-select-placeholder {
  color: var(--inte-G200) !important;
  font-size: 1.4rem;
}

.inte-formElement.inte-formElementTextfield:hover,
.inte-select--thick .inte-select.inte-formElement:hover,
.inte-formElement--hasSuffix .inte-formElement:hover+.inte-form__suffix {
  border-color: var(--inte-G50) !important;
}

.inte-form__itemHelp {
  font-size: 1.6rem;
}

/**Card elements**/
.inte-card.inte-card--bordered {
  padding: 2rem;
  border-radius: 6px;
}

/**Card ends**/
/**Text**/
.inte__text--positive {
  color: var(--inte-GR300) !important;
}

.inte__text--light {
  color: var(--inte-G200) !important;
}

.inte-TextLink--Label {
  display: inline-flex;
}

/**Text**/
/**Form elements end**/
.inte__menuItem {
  font-size: 16px;
  color: var(--inte-G450);
  font-weight: 600;
  line-height: 22px;
}

.language_select .inte-btn.inte__plain-btn {
  padding-right: 42px;
}

.language_select.inte-btn--Dropdown.inte-btn--Dropdown-Open>button span.inte-btn__icon {
  transform: unset;
}

/* .inte__Topbar-Connect--Right .inte-btn--onlyIcon {
  height: unset;
} */

.language_select>.inte-btn.inte__plain-btn::before {
  content: '|';
  position: absolute;
  right: 32px;
  top: 8px;
  color: rgb(224, 225, 227);
}

.language_select>.inte__plain-btn::after {
  content: url("data:image/svg+xml, %3Csvg width='18' height='18' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9.93652L12 15.9365L18 9.93652' stroke='%231c2433' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'%3E%3C/path%3E%3C/svg%3E");
  position: absolute;
  right: 6px;
  top: 10px;
  color: rgb(224, 225, 227);
  transition: 300ms all linear;
}

.language_select.inte-btn--Dropdown-Open .inte__plain-btn::after {
  transform: rotate(-180deg);
  top: 4px;
}

.inte-btn--Dropdown__Popover.language_select {
  width: 140px !important;
}

.language_select>.inte__plain-btn {
  background-color: var(--inte-transparet);
  color: var(--inte-G800);
  border: 0.1rem solid var(--inte-G50);
}

.language_select ul.inte-btn--Dropdown__Popover .inte-btn.inte-btn--hasIcon {
  justify-content: flex-start !important;
  align-items: center;
}

.inte__Sidebar {
  z-index: 9999;
}

.inte__Popover-Wrapper {
  z-index: 999999999;
}

.dropdownBtn.inte-btn--Dropdown__PopoverBody {
  z-index: 999999999;
  max-width: 150px;
}

/* .inte-AnnouncementBar ~ .inte--logoWrapper {
  top: 7rem;
} */

.inte--logoWrapper {
  left: 3rem;
}

@media screen and(max-width: 991px) {
  .inte__Sidebar {
    z-index: 999999;
  }
}

@media screen and(min-width: 992px) {
  .inte__Topbar {
    left: 250px;
  }

  .inte--logoWrapper {
    z-index: 99999 !important;
  }
}

.inte--logoWrapper {
  z-index: 99999 !important;
}

/**Tabs css**/
.inte-tabsWrap .inteTab--text span {
  font-size: 1.6rem;
  line-height: 22px;
  color: #4e4f52;
}

.inte-tabsWrap .inte__item--active .inteTab--text span {
  font-weight: 600;
  color: #413bbc;
}

.inte-tabsWrap {
  padding: 1.2rem 1.6rem 0;
  border: 1px solid #ecedee;
  background: #fafafb;
  border-radius: 6px;
}

.inte-tabsWrap a,
.inte-tabsWrap .inteTab--text {
  padding: 0 0.8rem 1.2rem;
}

/* .inte-tabsWrap {
    margin: 0 ;
  } */
/**Tabs css ends**/
/**Notification**/
.inte-notification {
  padding: 2rem 0;
}

.notify_dots>.inte-btn:before {
  content: url('data:image/svg+xml, %3Csvg className=%27%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2710%27 height=%2710%27 viewBox=%270 0 10 10%27 fill=%27none%27%3E%3Crect width=%2710%27 height=%2710.0003%27 rx=%275%27 fill=%27%23D62C20%27/%3E%3C/svg%3E');
  position: absolute;
  top: -10px;
  right: -5px;
}

/**Notification ends**/
/**Filter changes**/
.inte-FilterSheet--Header .inte-flex__item>.Paragraph.inte__text--dark {
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 24px;
}

/**Filter changes end**/
/**Grid **/

.ant-table-thead>tr>th,
.ant-table .ant-table-tbody>tr>td {
  line-height: 20px;
  font-size: 16px;
}

.ant-table-thead>tr>th {
  color: #1c2433;
  font-weight: 600;
}

.ant-table .ant-table-tbody>tr>td {
  font-weight: 400;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead>tr>th,
.ant-table.ant-table-middle .ant-table-tbody>tr>td,
.ant-table.ant-table-middle tfoot>tr>th,
.ant-table.ant-table-middle tfoot>tr>td {
  padding: 22px 16px;
}

.ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

.ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

.inte-Grid .ant-table,
.ant-table-content {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.griddata .inte-Grid-Table {
  border: 0;
}

.griddata {
  border: 1px solid #e0e1e3;
  border-radius: 6px;
}

.inte-Grid-Table .ant-table-tbody>tr>td {
  border-bottom: 1px solid var(--inte-G40);
}

/**Grid ends**/
/**Pagination css**/
.inte__Pagination--PageWrapper,
.inte__Pagination--ButtonWrapper,
.inte__Pagination--DotPageNumber {
  display: flex;
  align-items: center;
}

.inte__Pagination--DotPageNumber {
  padding: 0 0.8rem;
}

.inte__Pagination--wrapper .inte-btn {
  transition: none;
  min-height: 3.8rem;
  min-width: 3.8rem;
}

.inte__Pagination--PreviousBtn>button {
  height: 3.8rem !important;
  width: 3.8rem !important;
}

.inte__Pagination--NextBtn>button {
  height: 3.8rem !important;
  width: 3.8rem !important;
}

.inte__Pagination--wrapper .inte-btnNarrow {
  padding: 0 !important;
}

.inte__Pagination--wrapper .inte-btn .inte__text {
  line-height: 0 !important;
}

.inte__Pagination--TextWrapper {
  display: flex;
  padding-left: 1.8rem;
  align-items: center;
}

.inte__Pagination--TextWrapper>.inte__text {
  padding-right: 7.5px;
}

.inte__Pagination--TextField .inte-formElement--Wrap .inte-formElemet--Inner .inte-formElement {
  width: 4.1rem !important;
  height: 3.8rem !important;
}

.inte__Pagination--TextField .inte-formElement {
  padding: 0.9rem 0.8rem !important;
}

.inte__GoTo--NewPage {
  display: flex;
  align-items: center;
  padding-left: 7.5px;
  cursor: pointer;
}

.inte__GoTo--NewPage .inte__text {
  color: inherit !important;
}

.inte__GoTo--NewPage:hover {
  color: var(--inte-P900);
}

.inte__Pagination--Dropdown .inte-formElement {
  width: 6.3rem;
  height: 3.8rem;
}

.inte__Pagination--Dropdown [data-ellipsis='inte--ellipsis'] .inte__Select--Selected,
[data-ellipsis='inte--ellipsis'] .inte-Select__ChoiceList h3 {
  width: 100%;
  white-space: pre;
  overflow: unset;
  text-overflow: ellipsis;
}

.inte__Pagination--Dropdown .inte-formElemet__Arrow {
  right: 0.3rem;
}

.inte__Pagination .inte-formElemet__Arrow {
  border: 0 !important;
}

.inte__Pagination--Result_Wrapper {
  font-weight: 400;
  font-size: 1.6rem;
  color: #7e828b;
}

.inte__Pagination .inte-select-placeholder {
  color: var(--inte-G800) !important;
  font-weight: 600;
}

@media screen and(max-width: 991px) {
  .inte__Pagination--PreviousBtn {
    padding-right: 0.4rem;
  }

  .inte__Pagination--NextBtn {
    padding-left: 0.4rem;
  }
}

@media screen and(max-width: 500px) {
  .inte__Pagination--Result_Wrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 12.6rem;
  }
}

/**Pagination css ends**/
/**LRlayout css**/
.inte__l-title {
  font-weight: 600;
  line-height: 22px;
}

.inte__l-titleHelp {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #616771;
}

/**LRlayout css**/

.inte-Filter--Advance .inte-btn.inte-btn--Outlined {
  padding: 1rem 2.4rem !important;
  min-height: 4.4rem;
}

.inte--Tag-content span {
  font-size: 1.4rem;
}

/**Avatar overlap**/
.avataroverlap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.avataroverlap .inte--Avatar {
  margin-left: -10px;
}

/**Sorting button**/
.filter_btn .inte-btn.inte__plain-btn.inte-btn--hasIcon {
  height: unset;
  width: unset;
  min-height: unset;
  padding: 2px 3px;
}

/**Sorting button **/
/**Dashboard**/
.dashcard1 {
  box-shadow: -4px 0 0 rgba(2, 122, 72, 0.24);
}

.dashcard2 {
  box-shadow: -4px 0 0 rgba(218, 48, 33, 0.28);
}

.dashcard3 {
  box-shadow: -4px 0 0 rgba(23, 155, 215, 0.28);
}

.dashcard4 {
  box-shadow: -4px 0 0 #fec84b;
}

.dashboard_analyticscard {
  border-radius: 12px;
}

.dashboard_analyticscard .inte-btn.inte__plain-btn.inte-btnNarrow {
  min-height: unset;
  width: 2rem;
  height: 2rem;
}

.height_258 {
  height: 258px !important;
}

.height_450 {
  height: 450px !important;
}

@media screen and(max-width: 480px) {
  .inte__Main {
    padding: 7.2rem 1rem 7rem !important;
  }
}

/**Dashboard**/
/** Formedit section**/
.rounded_card {
  border-radius: 12px;
  border: 1px solid var(--inte-G40);
}

.domain_card {
  border-radius: 6px;
  border: 1px solid var(--inte-G40);
}

.accordian_card .inte__accordion {
  padding: 0;
  background-color: unset;
}

.inte-flex .inte-card.accordian_card {
  height: auto;
}

.inte__accordionBody>div {
  padding-top: 0.4rem;
}

.inte--switcher:checked {
  background: #413bbc;
}

.useraccount_upload .inte-Upload {
  border: 1px solid #e0e1e3;
  padding: 5px 18px;
  border-radius: 6px;
  height: 6.4rem;
  align-items: center;
  display: inline-flex;
  width: 100%;
}

.formedit_fileupload .ant-upload.ant-upload-select {
  border: 1px solid #e0e1e3;
  padding: 0 18px;
  border-radius: 6px;
  height: 4.4rem;
  align-items: center;
  display: inline-flex;
}

.ant-upload-list-item-name {
  font-size: 1.6rem;
}

.ant-upload-text-icon {
  display: flex;
}

.inte-Upload .inte-btn .inte__text {
  font-weight: 600;
  font-size: 1.4rem;
}

.paymentCard.active .inte-card {
  background: #f7f6fc;
  border: 1px solid #d0c6ee;
}

.paymentCard>.inte-card.inte-card--bordered {
  border-radius: 6px;
  padding: 26px 16px;
}

.color_input {
  width: 100%;
  background: transparent;
  position: absolute;
  border-radius: 100%;
  height: 100%;
  border: 0;
}

.color_card input[type='color']::-webkit-color-swatch {
  background-color: transparent !important;
  border: 0;
}

.themecolor {
  position: relative;
}

.themecolor.active:before {
  content: '';
  position: absolute;
  background-image: url('data:image/svg+xml, %3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 viewBox=%270 0 16 16%27 fill=%27none%27%3E%3Cpath d=%27M13.333 4.00024L6.00005 11.3332L2.66687 8.00006%27 stroke=%27white%27 strokeWidth=%271.33327%27 strokeLinecap=%27round%27 strokeLinejoin=%27round%27/%3E%3C/svg%3E');
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
}

.draft_Btn .inte__plain-btn,
.draft_Btn .inte__plain-btn .inte__text {
  color: #70747e;
  background-color: transparent;
  font-weight: 600;
}

/* .inte-btn .inte__text,
.payviacard .inte__Radion-Label {
    font-size: 16px;
    font-weight: 600;
} */
.bottom_border {
  margin: 20px 0;
  background-color: #e4e4e4;
  border: 0;
  height: 0.5px;
}

.accordian_card .inte-select-placeholder {
  color: #70747e !important;
  font-weight: 400;
}

.colorPickerbtn {
  width: 28px;
  height: 28px;
}

.max_height400 {
  max-height: 400px;
}

/**Formedit section end**/
/**Payment section**/
.plan_card {
  background-color: #fbfafe;
  border-top-left-radius: 12px;
  border-radius: 12px;
}

.plan_card::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #413bbc;
  height: 8px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.custom_Select .inte-select::after {
  content: '';
}

/**Payment section**/
/**Account**/
.activeplan {
  border: 2px solid #7a5dcf;
  background-color: #fbfafe;
}

.activeplan [class*='inte__text'] {
  color: #413bbc !important;
}

.activeplan .inte-form__Radio .inte__RadioFake:checked+.inte__RadioWrap>.inte__Radio {
  border: 0.15rem solid var(--inte-P900);
}

.activeplan .inte-form__Radio .inte__RadioFake+.inte__RadioWrap>.inte__Radio::after {
  background-color: var(--inte-P900);
}

.compressgrid .ant-table.ant-table-middle .ant-table-tbody>tr>td,
.compressgrid .ant-table.ant-table-middle .ant-table-thead>tr>th {
  padding: 12px 8px;
}

.compressgrid .inte-Grid-Table {
  border-radius: 10px;
}

/**Account**/
/* Hubspot content design */
.hubspot_textcolor {
  color: #33475b !important;
}

.colorCard {
  background-color: #e5f5f8;
  padding: 20px;
}

.gatewayColorCard {
  background-color: #e5f5f8;
  border: 2px solid #0091ae;
}

.inte-Modal-Dialog__Modal {
  border-radius: 8px;
}

.custom_footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  padding: 20px 10px 20px 0px;
  border-top: 1px solid #cbd6e2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.hidden {
  display: none;
}

.customTab {
  position: relative;
}

.customTab .inte-tabsWrap .inte__item--active a {
  border-bottom: 2px solid #0091ae !important;
}

.customTab .inte-tabsWrap .inte__item--active .inteTab--text span {
  color: #0091ae;
  font-weight: 400;
}

.customToastHubSpotError .inte__Toast--Error {
  background: #fdedee;
  border: 1px solid #f8a9ad;
  color: #33475b;
  border-radius: 0px;
}

.customToastHubSpotSuccess .inte__Toast--Success {
  background: #e5f8f6;
  border: 1px solid #7fded2;
  color: #33475b;
  border-radius: 0px;
}

.customAlert .inte-Alert--info {
  border: 2px solid #0091ae;
  background-color: #e5f5f8;
}

.customAlert svg {
  stroke: #0091ae;
}

.customSwitch .inte--switcher:checked {
  background-color: #0091ae;
}

.customSwitch .inte--switcher:checked:after {
  box-shadow: -0.5px 0.5px 8px #0091ae;
}

.couponList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.couponList>span {
  border: 1px solid #87c0a8;
  border-radius: 6px;
  margin: 0 10px 10px;
  width: 45%;
  padding: 8px 12px;
  text-align: center;
  background-color: #EEF6F3;
}

.CouponModal .inte-Modal-Header {
  background: linear-gradient(90deg, #00A4BC 1.05%, #00BCA5 99.97%);
}

.CouponModal .inte-Modal__Body .inte-Modal-Section .inte-flex .inte-flex--wrap .inte-flex--spacing-tight {
  padding-bottom: 10px !important;
}

.CouponModal .inte-Modal-Header button {
  background: #00BCA5;
}

.CouponModal .inte-Modal-Header button .inte-btn__icon svg {
  stroke: #FFFFFF;
}

.CouponModal .inte-Modal-Header__Title h3 {
  color: #FFFFFF !important;
  font-size: 20px;
  font-weight: 600 !important;
}

.customCheckbox .inte-form__checkbox .inte__checkoxFake:checked+.inte__checkboxWrap>.inte__checkbox,
.inte-form__checkbox .inte__checkoxFake:indeterminate+.inte__checkboxWrap>.inte__checkbox {
  background-color: #0091ae !important;
}

.customUpload .inte-btn--Primary {
  background-color: #0091ae;
  pointer-events: none;
  margin-bottom: 4px;
}

.customUpload .inte__text--light {
  display: none;
}

/* Hubspot content design ends*/
.custom_linkBtn .inte-btn.inte__text-btn {
  color: #fff;
}

.custom_prevNextBtn .inte-btn.inte__text-btn {
  color: #b0c1d4;
}

.custom_crmButton .inte-btn.inte-btn--Primary {
  color: #fff;
  background-color: #ff7a59;
}

.custom_crmButton .inte-btn.inte-btn-disable {
  color: #fff;
  background-color: #ff7a59 !important;
}

.custom_tooltip .inte__ToolTip-message {
  text-align: center;
  background: #222020;
}

/**Account**/
/**Account**/
/**Onboarding**/
.onboardbackimgs {
  background: url('../assets/images/onboardbackimg.png') no-repeat center;
  background: url('../assets/images/onboardgif.gif') no-repeat;
  background-size: cover;
  position: relative;
}

.onboardbackimgs:before {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.86);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.px-96 {
  padding: 0 96px;
}

.onboardcontact_btn .inte-btn {
  color: #fff;
}

.onboardcontact_btn .inte-btn:hover {
  color: var(--inte-G800);
}

.text_white {
  color: #fff !important;
}

.font-w_700 {
  font-weight: 700 !important;
}

.step_content {
  color: #f1f2f2 !important;
}

.onboarding_steps>li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 21px;
}

.onboarding_steps>li:before {
  content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clipPath='url(%23clip0_1261_87675)'%3E%3Crect width='24' height='24' rx='12' fill='%23F2F4F7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z' fill='%237F56D9'/%3E%3Crect x='0.75' y='0.75' width='22.5' height='22.5' rx='11.25' stroke='%23F2F4F7' strokeWidth='1.5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1261_87675'%3E%3Crect width='24' height='24' rx='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  position: absolute;
  left: 0;
  top: 0;
}

.onboarding_steps>li:after {
  content: '';
  position: absolute;
  left: 11px;
  top: 29px;
  width: 2px;
  height: 11px;
  background-color: #e4e7ec;
  border-radius: 2px;
}

.onboarding_steps>li:last-child::after {
  display: none;
}

.slide_animation {
  -webkit-animation-name: elementAnimation;
  animation-name: elementAnimation;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.is-sticky {
  backdrop-filter: blur(10px);
  animation: slideDown 0.6s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes elementAnimation {
  100% {
    right: 0;
    opacity: 1;
  }

  0% {
    right: -150px;
    opacity: 0.5;
  }
}

@-webkit-keyframes elementAnimation {
  100% {
    right: 0;
    opacity: 1;
  }

  0% {
    right: -150px;
    opacity: 0.5;
  }
}

/**Onboarding ends**/
/* Quotes Edit */
.quoteeditcard.paymentCard .inte-card.inte-card--bordered {
  padding: 15px 16px;
}

/* Quotes Edit Ends*/

.spin {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(186, 189, 182, 0.3);
  border-radius: 50%;
  border-top-color: #555753;
  margin-right: 5px;
  margin-left: 5px;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #4d2a8d;
  --gradient-color-2: #210c4d;
  --gradient-color-3: #210c4d;
  --gradient-color-4: #391c72;
}

#nprogress .bar {
  background: #413bbc !important;
  height: 3px !important;
  z-index: 99999 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #413bbc, 0 0 5px #413bbc !important;
  height: 3px !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.main-title-animated {
  background: linear-gradient(to right,
      #ff3364,
      #ff4360,
      #ff535d,
      #ff6359,
      #fe1fb4,
      #9f0cbd,
      #fe1fe0,
      #fe1ffe,
      #a91ffe,
      #a91ffe,
      #a91ffe);

  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.customToastStyle .inte__Toast--Success {
  background: #33a069;
}

.outlined {
  border: 2px solid black;
  padding: 2px;
  border-radius: 50%;
}

.fp-mb-\[16px\] {
  margin-bottom: 16px !important;
}

.fp_mt_4 {
  margin-top: 4px !important;
}

.fp_mt_16 {
  margin-top: 16px !important;
}

.fp_mt_20 {
  margin-top: 20px !important;
}

.fp_mt_30 {
  margin-top: 30px !important;
}

.fp_mb_4 {
  margin-bottom: 4px !important;
}

.fp_mb_10 {
  margin-bottom: 10px !important;
}

.fp_mb_28 {
  margin-bottom: 28px !important;
}

.fp_mb_14 {
  margin-bottom: 14px !important;
}

.fp_mb_16 {
  margin-bottom: 16px !important;
}

.fp_mb_20 {
  margin-bottom: 20px !important;
}

.fp_mb_30 {
  margin-bottom: 30px !important;
}

.fp_mb_40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 5px;
}

nextjs-portal {
  display: none;
}

.error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
  font-family: Arial, sans-serif;
}

.error-message {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.try-again-button {
  padding: 15px 20px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.try-again-button:hover {
  background-color: #0056b3;
}

.expire_announcement .inte-AnnouncementBar {
  background-color: #fef8f0;
}

.showCustomErrorDateExpire .inte--DatePicker__thick .ant-picker {
  border: 1px solid rgb(214, 44, 32);
}

.deal_language_switcher {
  position: absolute;
  top: 5px;
  right: 20px;
  z-index: 99999;
}

.deal_language_switcher .language_select .inte-btn.inte__plain-btn {
  padding: 8px 40px 8px 10px;
  height: unset;
}

.deal_language_icon .inte-btn__icon {
  margin-bottom: -8px;
}

.pricing_card {
  transition: background-color 0.3s ease, border 0.3s ease;
}

.pricing_card:hover {
  background-color: #F9F5FE;
  border: 1px solid #7936F4;
}

.pricing_card button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pricing_card:hover button {
  background-color: #7936F4;
  color: #FFFFFF;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.plan_component {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: .3s ease-out 0s 1 slideInFromLeft;
}

.disabled_switch .inte--switcher {
  cursor: not-allowed;
}

.custom_trial_extend-btn .inte-btn.inte__text-btn .inte__text {
  text-decoration: underline;
  font-weight: 400;
}

.forms_multiAction #actionDisable {
  opacity: 0.5;
  cursor: not-allowed;
}

.li-w-100>li>button {
  width: 100%;
  text-align: start;
}

.custom_choicelist .inte--Tag__Dismissible button {
  display: none;
}

.portal--active .inte-card--bordered {
  border: 0.29rem solid rgb(67, 27, 188);
}

.disable_menu_item {
  pointer-events: none;
}

.disable_menu_item .inte__menuIcon svg path {
  stroke: #b8b8b8;
}

.disable_menu_item .inte__menuItem a {
  color: #b8b8b8;
}

.disable_dashboard_menu {
  pointer-events: none;
}

.disable_dashboard_menu .inte__Menus {
  background-color: #FFFFFF;
}

.disable_dashboard_menu .inte__menuIcon svg g path {
  stroke: #b8b8b8;
}

.disable_dashboard_menu .inte__menuItem a {
  color: #b8b8b8;
}

.disable_dashboard_menu .inte__Menus::after {
  background-color: #FFFFFF !important;
}

.confirmationModal>div {
  background-color: rgba(43, 43, 43, 0.442);
  backdrop-filter: blur(8x);
}

.filter-applied {
  position: relative;
}

.applied-number {
  position: absolute;
  background-color: #413bbc;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: 700;
  aspect-ratio: 1;
  padding: 5px;
  color: white;
  top: -8px;
  left: 5px;
  z-index: 9999999;
}



.card_disable--backdrop {
  position: relative;
}

.card_disable--backdrop::before {
  content: '';
  height: 100%;
  width: 100%;
  z-index: 9999 ;
  border-radius: 10px;
  cursor: not-allowed;
  background-color: rgba(206, 206, 206, 0.416);
  backdrop-filter: grayscale(0.5) opacity(0.8);
  position: absolute;

}

.ellipsis-container {
  width: 100%; /* Set a fixed width for the container */
  overflow: hidden;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  transition: text-overflow 0.3s; /* Add transition for smooth effect */
}

.ellipsis-container:hover .ellipsis-text {
  white-space: normal;
  font-weight: 700;
  text-overflow: unset;
}
.inte__text.shadow-text{
  color: grey !important;
}
.noBreak{
  white-space:nowrap !important;
}
.subscription-payments{
  width:100%;
  padding:5px 0px;
  padding-left:5px;
  border:1px solid var(--inte-G42);;
  margin-left: 5px
}

.shepherd-arrow:before {
  box-shadow:0 2px 4px rgba(0,0,0,.2);
 
}


.shepherd-modal {
  background-color: #fff;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  opacity: 0.2 !important; 
}

/* Change font color of the popup title */
.shepherd-modal .shepherd-header h3 {
  color: #333;
}

/* Change font color of the popup text */
.shepherd-modal .shepherd-text {
  color: #666;
}

/* Change background color of the buttons */
.shepherd-modal .shepherd-footer .shepherd-button {
  background-color: #007bff;
  color: #fff;
}

/* Change background color of the buttons on hover */
.shepherd-modal .shepherd-footer .shepherd-button:hover {
  background-color: #0056b3;
}

/* Change font color of the buttons on hover */
.shepherd-modal .shepherd-footer .shepherd-button:hover {
  color: #fff;
}

.shepherd-title {
  color: rgba(0,0,0,.75);
  display: flex;
  flex: 1 0 auto;
  font-size: 2rem  !important;
  font-weight: 700 !important;
  margin: 0;
  padding: 0;
}

.shepherd-text {
  color: rgba(0,0,0,.75);
  font-size: 1.5rem !important;
  line-height: 1.3em;
  padding: 0.75em;
  font-weight: 500 !important;

}

.shepherd-button-primary {
  background-color: var(--inte-P900) !important;
 
}