.inte-Loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  flex-direction: column;
  gap: 1.6rem;
}
.inte-Loader--Items {
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
#Rectangle {
  -webkit-animation: 1.2s colorchange ease-in-out infinite;
  animation: 1.2s colorchange ease-in-out infinite;
}
#Polygon {
  -webkit-animation: 1.2s colorchange ease-in-out infinite 0.15555s;
  animation: 1.2s colorchange ease-in-out infinite 0.15555s;
}
#Ellipse {
  -webkit-animation: 1.2s colorchange ease-in-out infinite 0.3s;
  animation: 1.2s colorchange ease-in-out infinite 0.3s;
}
@keyframes colorchange {
  0%,
  40%,
  100% {
    fill: #d9d9d9;
  }
  40% {
    fill: #431bbc;
  }
}
@-webkit-keyframes colorchange {
  0%,
  40%,
  100% {
    fill: #d9d9d9;
  }
  40% {
    fill: #431bbc;
  }
}
/*Page Loader*/
.inte-pageLoader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffffe6;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inte-pageLoader .inte-loading {
  border: 2px solid var(--inte-G40);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 2px solid var(--inte-G200);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}
.inte-loading--Transform {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--inte-G40);
}
.inte-loading--Transform b {
  transform: scaleY(0);
  -webkit-animation: ounce-transform 1.5s infinite
    cubic-bezier(0.76, 0, 0.24, 1);
  animation: ounce-transform 1.5s infinite cubic-bezier(0.76, 0, 0.24, 1);
}
.inte-loading--Transform b:nth-child(1) {
  animation-delay: 0s;
}
.inte-loading--Transform b:nth-child(2) {
  animation-delay: 0.15s;
}
.inte-loading--Transform b:nth-child(3) {
  animation-delay: 0.3s;
}
.inte-loading--Transform b:nth-child(4) {
  animation-delay: 0.45s;
}
.inte-loading--Transform b:nth-child(5) {
  animation-delay: 0.6s;
}
.inte-loading--Transform b:nth-child(6) {
  animation-delay: 0.75s;
}
.inte-loading--Transform b:nth-child(7) {
  animation-delay: 0.9s;
}

.inte-Loader--TypeThree {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffffe6;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inte-Loader--TypeThreeInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80rem;
  width: 100%;
  padding: 0 1.6rem;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes ounce-transform {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  25% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  75% {
    transform-origin: 50% 0%;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scaleY(0);
  }
}
@-webkit-keyframes ounce-transform {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  25% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  75% {
    transform-origin: 50% 0%;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scaleY(0);
  }
}
/*Page loader end */

@media screen and (max-width: 768px) {
  .inte-Loader--Icon svg {
    width: 80px;
    height: 80px;
  }
}

[data-theme='dark'] .inte-pageLoader {
  background-color: #333333e6;
}

.inte-progressbar__wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.inte__progressbar {
  width: 100%;
  border-radius: var(--radius-rounded-5);
  background-color: var(--inte-P30);
  position: relative;
  margin-right: 12px;
  overflow: hidden;
}

.inte__progressbar--status h3 {
  right: 0;
  color: var(--inte-G0);
  border-radius: var(--radius-rounded-4);
  z-index: 1;
  font-size: 1.1rem;
  transition: 100ms all linear;
  opacity: 1;
  visibility: visible;
  line-height: 13px;
}

.inte__progressbar--thin {
  height: 0.8rem;
}

.inte__progressbar--thick {
  height: 1.2rem;
}

.inte__progressbar--status {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: var(--radius-rounded-5);
  transition: 300ms all ease-in-out;
  background-color: var(--inte-P500);
}

.inte__progressbar--status-inprogress::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(39, 9, 131, 0.7) 0,
    rgba(39, 9, 131, 0.639) 50%,
    rgba(39, 9, 131, 0.459) 100%
  );
  -webkit-animation: shimmer 2s ease-out infinite;
  animation: shimmer 2s ease-out infinite;
  content: '';
}

.inte-progressbar__text {
  font-size: var(--inte-FS1-6);
  font-weight: var(--weight-normal);
  line-height: var(--inte-LH2-0);
  color: var(--inte-G800);
}

@keyframes shimmer {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 0;
  }
}

@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 0;
  }
}

/*ProgressCircular*/
.inte-circle-chart__circle {
  animation: circle-chart-fill 2s reverse;

  transform: rotate(-90deg);

  transform-origin: center;
}

.inte-circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
}

.inte-progressCircle--large {
  width: 6.4rem;
  height: 6.4rem;
}

.inte-progressCircle--small {
  width: 3.2rem;
  height: 3.2rem;
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
  }
}

/*Progress bar End end*/

[data-theme='dark'] .inte-circle-chart__percent {
  fill: var(--dark-color);
}

.inte-progressbar__toolTip {
  position: absolute;
  transform: translateX(-50%);
  bottom: -5.3rem;
  transition: 300ms all ease-in-out;
  transform: translateX(-50%);
  background-color: var(--inte-G800);
  color: var(--inte-G0);
  border-radius: 0.6rem;
  height: 4.4rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  font-size: 1.6rem;
  font-weight: 600;
}

.inte-progressbar__toolTip::after {
  content: '';
  position: absolute;
  top: -10px;
  transform: rotate(180deg);
  margin-left: -0.5rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: var(--inte-G800) transparent transparent transparent;
}
